import React, { useMemo } from 'react';
import { editProfile } from 'models/manualControlOfCouses/actions';
import { editEducationProfile } from 'models/manualControlOfCouses/api';
import { useDispatch } from 'react-redux';
import cloneDeep from 'lodash/cloneDeep';
import ModalV2 from 'components/modal-v2';
import { successMessage } from 'utils/message';
import { errorMessageCatcher } from 'utils/request';
import FormDirection from '../form';


const EditDirectionModal = ({ values, isModalVisible, onClose }) => {
  const dispatch = useDispatch();

  const onEditDirection = async (profile) => {
    try {
      const response = await editEducationProfile({ id: values?._id, profile });
      dispatch(editProfile(response._id, profile));
      successMessage('Запись удалена');
    } catch (e) {
      errorMessageCatcher(e);
    }

    onClose();
  };

  const initialValues = useMemo(() => {
    const init = cloneDeep(values);

    return init;
  }, [values]);

  return (
    <ModalV2
      title="Редактировать запись"
      visible={isModalVisible}
      width={992}
      closable={false}
      footer=""
      tooltipClose="После отмены восстановление заполненных  полей невозможно"
      onClose={onClose}
      destroyOnClose
    >
      <FormDirection
        onSubmitHandler={onEditDirection}
        initialValues={initialValues}
        onClose={onClose}
      />
    </ModalV2>
  );
};

export default EditDirectionModal;

import React, { useEffect, useState } from 'react';
import './style.less';
import bem from 'easy-bem';
import {
  Radio, Space, Form, Input, Select
} from 'antd';
import TextEditor from 'components/text-editor';
import { requiredField, rulesValidationThree } from 'utils/validation/validation';
import { getBitrixCategory } from 'models/filters/selectors';
import { fetchBitrixCategory } from 'models/filters/actions';
import { useSelector, useDispatch } from 'react-redux';
import { inputStyle } from '../modal-upload/component';


const { Option } = Select;

const ModalPrice = ({ form }) => {
  const b = bem('modal-price');
  const dispatch = useDispatch();
  const [currentValue, setCurrentValue] = useState(form.getFieldValue('priceType') || 'full_price');
  const [price, setPrice] = useState(form.getFieldValue('price'));
  const [countMonth, setCountMonth] = useState(form.getFieldValue('month'));
  const bitrixCategory = useSelector(getBitrixCategory);

  // eslint-disable-next-line no-restricted-globals
  const calculation = (dividend, divider) => ((isNaN(dividend) || !dividend) || (isNaN(divider) || !divider)
    ? ''
    : ((Math.ceil((dividend / (divider || 1)) * 100)) / 100));

  useEffect(() => {
    dispatch(fetchBitrixCategory());
  }, [dispatch]);

  useEffect(() => {
    const values = form.getFieldsValue();
    if (values.priceType !== 'full_price') {
      form.setFieldsValue({ ...values, payment: calculation(price, countMonth) });
    }
  }, [price, countMonth, form]);

  const handleChangePaymentType = (event) => {
    const { value } = event.target;
    const resetFields = ['price', 'payment', 'month'];
    const initialValues = {
      price: value !== 'free' ? price : 0,
      payment: 0,
      month: 0,
    };
    resetFields.forEach((field) => form.setFieldValue(field, initialValues[field]));

    setCurrentValue(value);
  };

  return (
    <div className={b()}>
      <Form.Item name="priceType">
        <Radio.Group
          onChange={handleChangePaymentType}
          value={currentValue}
        >
          <Space direction="horizontal">
            <Radio value="full_price">
              <span className={b('checkbox-text')}>
                Указать стоимость
              </span>
            </Radio>
            <Radio value="installment">
              <span className={b('checkbox-text')}>
                Возможна оплата частями
              </span>
            </Radio>
            <Radio value="free">
              <span className={b('checkbox-text')}>
                Бесплатно
              </span>
            </Radio>
          </Space>
        </Radio.Group>
      </Form.Item>
      <div className={b('head-inputs')}>
        <Form.Item
          name="price"
          label="Стоимость курса, руб."
          rules={currentValue !== 'free' ? [requiredField, rulesValidationThree] : []}
          className={b('info-input')}
        >
          <Input
            style={{ ...inputStyle }}
            size="large"
            placeholder="Ввод текста"
            disabled={currentValue === 'free'}
            onChange={(e) => (currentValue === 'installment' || currentValue === 'full_price')
              && setPrice(e.target.value)}
          />
        </Form.Item>
        <Form.Item
          name="month"
          label="Количество месяцев рассрочки"
          rules={currentValue === 'installment' ? [requiredField, rulesValidationThree] : []}
          className={b('info-input')}
        >
          <Input
            style={{ ...inputStyle }}
            size="large"
            placeholder="Ввод текста"
            disabled={
              currentValue === 'free'
              || currentValue === 'full_price'
            }
            onChange={(e) => currentValue === 'installment' && setCountMonth(e.target.value)}
          />
        </Form.Item>
        <Form.Item
          name="payment"
          label="Ежемесячный платеж, руб."
          rules={[rulesValidationThree]}
          className={b('info-input')}
        >
          <Input
            style={{ ...inputStyle }}
            size="large"
            disabled
          />
        </Form.Item>
      </div>
      <Form.Item
        className={b('textarea')}
        label="Условия оплаты"
        name="paymentTerm"
      >
        <TextEditor
          size="large"
          placeholder="Ввод текста"
        />
      </Form.Item>
      <Form.Item
        name={['bitrixData', 'assignedUserId']}
        label="ID ответственного в битриксе"
        rules={[rulesValidationThree]}
        className={b('bitrix-input')}
      >
        <Input
          style={{ ...inputStyle }}
          size="large"
          placeholder="Пример: 2939"
        />
      </Form.Item>
      <Form.Item
        name={['bitrixData', 'categoryId']}
        label="Передача заявок в битрикс"
        rules={[requiredField]}
        className={b('bitrix-input')}
      >
        <Select
          style={{ ...inputStyle, width: '40%' }}
          size="large"
          placeholder="Выберите вариант"
        >
          {bitrixCategory.map((item, i) => (
            <Option key={`category-${i}`} value={item.value}>{item.label}</Option>
          ))}
        </Select>
      </Form.Item>
    </div>
  );
};

export default ModalPrice;

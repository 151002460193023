import React, { useEffect } from 'react';
import { ReactComponent as DoneWarning } from 'assets/icons/done-warning-icon.svg';
import { useDispatch, useSelector } from 'react-redux';
import { unPublishCourse } from 'models/courses/actions';
import { unPublishCourse as unPublishCourseFromApi } from 'models/courses/api';
import { cancelPromo, fetchPromosActual } from 'models/blogAndAdvertising/actions';
import { getPromos } from 'models/blogAndAdvertising/selectors';
import { successMessage } from 'utils/message';
import { errorMessageCatcher } from 'utils/request';
import ModalAdminCourse from '../component';


const RemovePublicationModal = ({ isModalVisible, onClose, id }) => {
  const dispatch = useDispatch();
  const promos = useSelector(getPromos);
  const activePromo = promos?.items.find((item) => item.course._id === id);

  useEffect(() => {
    dispatch(fetchPromosActual());
  }, [dispatch]);

  const onHandleClick = async () => {
    try {
      const response = await unPublishCourseFromApi(id);
      dispatch(unPublishCourse(response));
      successMessage('Курс перемещен в черновик!');
      if (activePromo) {
        dispatch(cancelPromo(activePromo._id));
      }
    } catch (e) {
      errorMessageCatcher(e);
    }

    onClose();
  };

  return (
    <ModalAdminCourse
      title="Снятие с публикации"
      visible={isModalVisible}
      width="707px"
      onClose={onClose}
      isModalVisible={isModalVisible}
      onHandleClick={onHandleClick}
      icon={<DoneWarning />}
      contentText={`После снятия с публикации курс помещается в черновик.
      Вы уверены, что хотите снять курс с публикации?
      ${activePromo && 'Курс находитмя в промоакциях.'}`}
      submitText="Снять с публикации"
      buttonWidth={180}
    />
  );
};

export default RemovePublicationModal;
